<template>
  <div id="app">
	<a href="#reporter-content" id="skip-nav" rel="noopener">
      Skip Navigation Links
    </a>

	<template v-if="applicationMsg">
		<b-collapse class="collapse sysAppMessage" visible id="applicationMsgInfo">
			<template v-for="(msg, ind) in applicationMsg.info">
				<button v-if="ind == 0 && msg.message" type="button" class="btn-close" v-b-toggle.applicationMsgInfo aria-label="Close"></button>
				<div class="alert m-0 alert-info" :class="getClass(ind, applicationMsg.info.length)" role="alert" v-if="msg.message" v-html="msg.message">
				</div>
			</template>
		</b-collapse>
		<b-collapse class="collapse sysAppMessage" visible id="applicationMsgWarning">
			<template v-for="(msg, ind) in applicationMsg.warning">
				<button v-if="ind == 0 && msg.message" type="button" class="btn-close" v-b-toggle.applicationMsgWarning aria-label="Close"></button>
				<div class="alert m-0 alert-warning"
					:class="getClass(ind, applicationMsg.warning.length)" role="alert" v-if="msg.message" v-html="msg.message">
				</div>
			</template>
		</b-collapse>
		<b-collapse class="collapse sysAppMessage" visible id="applicationMsgError">
			<div v-for="(msg, ind) in applicationMsg.error">
				<button v-if="ind == 0 && msg.message" type="button" class="btn-close" v-b-toggle.applicationMsgError aria-label="Close"></button>
				<div class="alert m-0 alert-danger" :class="getClass(ind, applicationMsg.error.length)" role="alert" v-if="msg.message" v-html="msg.message">
				</div>
			</div>
		</b-collapse>
	</template>

    <div class="position-relative">
		<div id="nav">
		<navigation></navigation>
		</div>

		<div id="reporter-content">
			<router-view />
		</div>
		<div class="col-10 offset-1">
		<footer-nav  :exporterPage="false" v-if="showFooter"/>
		</div>
	</div>

	<Qualtrics />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import FooterNav from '@/components/Footer.vue';
import { mapGetters } from 'vuex';
import Qualtrics from '@/components/global/qualtrics.vue';

export default {
	name: 'app',
	compatConfig: { MODE: 3 },
	components: {
		Navigation,
		FooterNav,
		Qualtrics
	},
	data: function () {
		return {
			showFooter: true,
			applicationMsg: null
		};
	},
	computed: {
		...mapGetters(['externalURLs'])
	},
    watch: {
        '$route.name': function (val) {
            switch (val) {
				case 'smartSearch':
				case 'home':
				case 'publications-search':
				case 'advanced-search':
				case 'matchmaker':
					this.showFooter = true;
					break;
				default:
					this.showFooter = false;
            }
        }
    },
	async created() {
		var requestedURLs = [
            'reporter_application_message'
        ];

        await this.$store.dispatch('getAppSettingsPropertyByNameAction', requestedURLs);

		this.getMessage();
	},
	methods: {
		getMessage () {
			if(this.externalURLs && this.externalURLs.reporter_application_message) {
				var msg = this.externalURLs.reporter_application_message;
				var formattedJSON = JSON.parse(JSON.stringify(eval('(' + msg + ')')));
				var msgTypes = formattedJSON.message;
				this.applicationMsg = {};
				for (let i = 0; i < msgTypes.length; i++) {
					if (!this.applicationMsg[msgTypes[i].type]) {
						this.applicationMsg[msgTypes[i].type] = [];
					}
					this.applicationMsg[msgTypes[i].type].push(msgTypes[i]);
				}
			}
		},
		getClass (ind, totalCnt) {
			if (totalCnt === 1) {
				return '';
			} else {
				if (ind === 0) {
					return 'border-bottom-0';
				} else {
					return 'border-top-0';
				}
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/scss/main.scss';
.sysAppMessage {
	.fas {
		z-index: 101;
		position: relative;
		&:focus {
			outline: none;
		}
	}
	.btn-close {
		position: absolute;
		z-index:200;
		right:5px;
		top:5px;
	}
}

</style>